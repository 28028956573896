import React from 'react'
import {graphql, Link} from "gatsby";
import Seo from "../components/Seo/seo";
import Layout from "../components/Layout/layout";
import PageBody from "../components/PageBody/pageBody";
import styled from 'styled-components'

const Template = styled(({data, className}) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return (
    <>
        <Seo slug=""/>
        <Layout>
          <PageBody h1={frontmatter.title}>
            { frontmatter.date && <p>{frontmatter.date}</p> }
            <div className={`${className}__content ${className}__content--${frontmatter.category}`}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </PageBody>
        </Layout>
    </>
  )
})`
  &__content {
    &--term {
      & > h2:first-child {
        margin-top: 0;
      }
      
      ol, ul {
        margin-left: 1em;
      }
      
      h3 {
        margin-top: 0;
      }
      
      p {
        margin-bottom: 1em;
      }
    }
  }
`

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        category
      }
    }
  }
`

// export default Template
export default () => {
    if (typeof window !== 'undefined') {
        window.location = '/';
    }

    return null;
}
